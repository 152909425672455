<template>
	<div>
		<v-row justify="center">
			<v-col align-self="left" sm="6" md="6">
				<div class="card-h" @click="$router.push('/bet')">
					<v-img
						style=" border-radius: 6px;"
						max-height="calc(100vh - 112px )"
						contain
						src="../assets/img/hipico.jpg"
					></v-img>
				</div>
			</v-col>
			<v-col sm="6" md="6">
				<div class="card-h" @click="$router.push('/casino')">
					<v-img
						style=" border-radius: 6px;"
						max-height="calc(100vh - 112px )"
						contain
						src="../assets/img/casino.jpg"
					></v-img>
				</div>
			</v-col>
		</v-row>
	</div>
</template>

<script>
export default {
  mounted() {

  },
  methods: {

  }

};
</script>

<style>
.card-h {
	filter: grayscale(0.5);
	cursor: pointer;
	transition: ease-in-out 250ms;
}

.card-h:hover {
	filter: none;
	transform: scale(1.02);
}
</style>
